<template>
  <!-- <div class="upgrade-management-container">
    <div class="msg-list-tools">
			<el-form :inline="true">
				<el-form-item label="任务名称:">
					<el-input v-model="search.name" style="width: 220px;" placeholder="输入任务名称"></el-input>
				</el-form-item>
				<el-form-item label="设备类型:">
					<el-tree-select 
						ref="group-select-tree"
						class="group-select-tree" 
						v-model="search.name"
						:selectParams="selectParams" 
						:treeParams="treeParams" 
					>
					</el-tree-select>
				</el-form-item>
				<el-form-item label="发生时间:">
          <el-date-picker
            v-model="search.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <div style="float: right;">
          <el-form-item>
          <el-button type="primary" size="small"  @click="getData">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </div>
			</el-form>
		</div>
		<div class="mt10">
			<addUser :roleList="roleList" :getDataR="getDataR"  operateType="add">
				<el-button type="primary">新建</el-button>
			</addUser>
		</div>
		<el-table class="mt20 ft14 c333 it1" style="width: 100%" height="100%" highlight-current-row stripe :data="list" key="MenuList">
			<el-table-column prop="userName" label="任务名称">
			</el-table-column>
			<el-table-column prop="mobile" label="设备类型">
			</el-table-column>
			<el-table-column prop="createTime" label="升级类型" width="200">
			</el-table-column>
			<el-table-column prop="status" label="目标版本" width="100">
				<template slot-scope="scope">
					<span style="color: #008000;" v-if="scope.row.status ==1 ">启用</span>
					<span style="color: #FF0000;" v-else>停用</span>
				</template>
			</el-table-column>
			<el-table-column prop="org.orgName" label="当前版本">
			</el-table-column>
			<el-table-column prop="accountType" label="目标数量" width="100">
				<template slot-scope="scope">
					<span style="color: #008000;" v-if="scope.row.accountType ==1 ">永久</span>
					<span style="color: #FF0000;" v-else>临时</span>
				</template>
			</el-table-column>
			<el-table-column prop="role.roleName" label="完成度">
			</el-table-column>
			<el-table-column prop="lastLoginTime" label="小时升级数量" min-width="150">
			</el-table-column>
      <el-table-column prop="lastLoginTime" label="允许升级时间" min-width="150">
			</el-table-column>
      <el-table-column prop="lastLoginTime" label="任务创建时间" min-width="150">
			</el-table-column>
			<el-table-column label="操作" min-width="170">
				<template slot-scope="scope">
					<a class="operate-a ml10" @click="deleteUser(scope.row.id)">删除</a>
				</template>
			</el-table-column>
		</el-table>
		<div class="page-container">
			 <div class="table-page-container">
				<el-pagination 
					background 
					:current-page="page" 
					:page-sizes="[10, 20, 30, 40]" 
					:page-size="pageSize" 
					layout="total, sizes, prev, pager, next, jumper"
					:total="total" 
					@size-change="handleSizeChange" 
					@current-change="handleCurrentChange"
				>
				</el-pagination>
			 </div>
		</div>
  </div> -->
	<iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
</template>

<script>
import './Index.less';
export default {
  name: 'UpgradeManagement',
  data() {
    return {
			url: 'http://iotp.fast-fun.cn/#/upgradeTask',
      search: {
        
      }
    }
  }
}
</script>